import React, { useState } from 'react';

const CreateSymbol = ({ onSymbolSuccess, owner }) => {
    const [symbolData, setSymbolData] = useState({
        symbol: '',        // 符号
        description: '',   // 描述
        initialPrice: 0,   // 初始价格，范围为 0 到 1
        deadline: '',      // 截止日期
    }); 
    const [message, setMessage] = useState('');
    // const [classification, setClassification] = useState(null);

    // 定义多语言翻译
    const translationsForCreateSymbol = {
        en: {
            title: 'Create a Symbol',
            symbol: 'symbol',
            description: 'Description',
            initialPrice: 'Initial Price',
            create: 'Create Symbol',
            success: 'Symbol successfully created!',
            fail: 'Symbol creation failed: ',
            error: 'An error occurred. Please try again.',
            priceError: 'Initial Price must be between 0 and 1',
            deadline: 'Set a deadline',
            deadlineError: 'Deadline must be a valid future date',
        },
        zh: {
            title: '创建符号',
            symbol: '名字',
            description: '描述',
            initialPrice: '初始价格',
            create: '创建符号',
            success: '符号创建成功！',
            fail: '符号创建失败：',
            error: '发生错误，请重试。',
            priceError: '初始价格必须在 0 到 1 之间',
            deadline: '设定截止时间',
            deadlineError: '截止日期必须是有效的未来日期',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsForCreateSymbol[curlanguage][key]; // 翻译函数

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSymbolData({
            ...symbolData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("The owner (username) is:", owner); // 用于调试

        if (symbolData.initialPrice < 0 || symbolData.initialPrice > 1) {
            setMessage(t('priceError'));
            return;
        }

        const deadlineDate = new Date(symbolData.deadline);
        if (isNaN(deadlineDate) || deadlineDate <= new Date()) {
            setMessage(t('deadlineError'));
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...symbolData,
                    owner  // 将 owner 直接传递给后端，代表用户名
                }),
                credentials: 'include',
            });

            if (response.ok) {
                const result = await response.json();
                setMessage(t('success'));
                setSymbolData({ symbol: '', description: '', initialPrice: 0, deadline: '' });
                // const classifyResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/classify`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({ query: result.symbol.symbol }),
                // });

                // if (classifyResponse.ok) {
                //     const classifyResult = await classifyResponse.json();
                //     setClassification(classifyResult.classification);
                // } else {
                //     const errorData = await classifyResponse.json();
                //     setMessage(`Classification failed: ${errorData.error}`);
                // }

                if (onSymbolSuccess) onSymbolSuccess(result); 
            } else {
                const errorData = await response.json();
                setMessage(`${t('fail')}${errorData.message}`);
            }
        } catch (error) {
            setMessage(t('error'));
        }
    };

    return (
        <div>
            <h2>{t('title')}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>{t('symbol')}:</label>
                    <input
                        type="text"
                        name="symbol"
                        value={symbolData.symbol}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>{t('description')}:</label>
                    <input
                        type="text"
                        name="description"
                        value={symbolData.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>{t('initialPrice')}:</label>
                    <input
                        type="number"
                        name="initialPrice"
                        value={symbolData.initialPrice}
                        onChange={handleChange}
                        step="0.01"
                        min="0"
                        max="1"
                        required
                    />
                </div>
                <div>
                    <label>{t('deadline')}:</label>
                    <input
                        type="datetime-local"
                        name="deadline"
                        value={symbolData.deadline}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">{t('create')}</button>
            </form>
            {message && <p>{message}</p>}
            {/* {classification && (
                <p>
                    {t('classificationResult')} <strong>{classification}</strong>
                </p>
            )} */}
        </div>
    );
};

export default CreateSymbol;
