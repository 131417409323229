import React, { useState } from 'react';
import './cssfiles/Form.css'; // 创建一个单独的 CSS 文件来存放表单样式

const Signup = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: ''
    });
    const [message, setMessage] = useState('');



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const translationsforsignup = {
        en: {
            title: 'Sign Up',
            username: 'Username',
            email: 'Email',
            password: 'Password',
            signup: 'Sign Up',
            success: 'Signup successful!',
            fail: 'Signup failed: ',
            error: 'Error signing up: ',
        },
        zh: {
            title: '注册',
            username: '用户名',
            email: '邮箱',
            password: '密码',
            signup: '注册',
            success: '注册成功！',
            fail: '注册失败：',
            error: '注册错误：',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsforsignup[curlanguage][key]; // 翻译函数





    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setMessage('Signup successful!');
                setFormData({ username: '', email: '', password: '' });
            } else {
                const errorData = await response.json();
                setMessage('Signup failed: ' + errorData.message);
            }
        } catch (error) {
            setMessage('Error signing up: ' + error.message);
        }
    };

    return (
        <div className="wrapper">
            <h2>{t('title')}</h2>
            {message && <p className="message">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="input-field">
                    <label></label>
                    <input
                        type="text"
                        name="username"
                        placeholder={t('username')}
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input-field">
                    <label></label>
                    <input
                        type="email"
                        name="email"
                        placeholder={t('email')}
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input-field">
                    <label></label>
                    <input
                        type="password"
                        name="password"
                        placeholder={t('password')}
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">{t('signup')}</button>
            </form>
        </div>
    );
};

export default Signup;
