import React, { useState, useEffect } from 'react';
import Signup from './component/Signup';
import Signin from './component/Signin';
import SigninWithGoogle from './component/SigninWithGoogle';
import CreateSymbol from './component/CreateSymbol';
import SymbolList from './component/SymbolList';
import AnonymousChat from './component/AnonymousChat';
import UserPnlDetails from './component/UserPnlDetail';
import LanguageSwitcher from './component/LanguageSwitcher';
import Leaderboard from './component/leaderboard';

function App() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeSection, setActiveSection] = useState('symbols'); 
    const [language, setLanguage] = useState('eng'); 
    const [isSignUpVisible, setIsSignUpVisible] = useState(false);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('app-language');
        if (savedLanguage && (savedLanguage === 'eng' || savedLanguage === 'zh')) {
            setLanguage(savedLanguage);
        }
        fetchSession(); // 初次加载时调用
    }, []);


    const t = (key) => translations[language][key] || key; // 翻译函数

    
    const translations = {
        eng: {
            welcomeMessage: 'Welcome to Intergamble',
            greeting: 'Hello,',
            symbols: 'Symbols',
            createSymbol: 'Create Symbol',
            myPnl: 'My PnL',
            signOut: 'Sign Out',
            notAuthenticated: 'Not authenticated',
            loggedOut: 'Successfully logged out!',
            signedInAs: 'Signed in as:',
            chat: 'Chat',
            leaderboard: 'leaderboard',
            signininfo: 'Already have an account? Sign in here',
            signupinfo:'Do not have an account? Sign up here',

        },
        zh: {
            welcomeMessage: '欢迎来到Intergamble',
            greeting: '你好, ',
            symbols: '符号列表',
            createSymbol: '创建符号',
            myPnl: '我的盈亏',
            signOut: '退出登录',
            notAuthenticated: '未认证',
            loggedOut: '成功退出登录!',
            signedInAs: '已登录为:',
            chat: '聊天',
            leaderboard: '排行榜',
            signininfo: '已经有账号了？在这登陆',
            signupinfo:'还没有账号？在这注册',
        },
    };

    const fetchSession = () => {
        setIsLoading(true); // 开始加载
        fetch(`${process.env.REACT_APP_API_URL}/api/auth/session`, {
            method: 'GET',
            credentials: 'include',
            // mode: 'no-cors',

        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Not authenticated');
            })
            .then((data) => {
                setIsSignedIn(true);
                setUser(data.user);
            })
            .catch(() => {
                setIsSignedIn(false);
                setUser(null);
            })
            .finally(() => setIsLoading(false)); // 加载结束
    };

    const handleSignInSuccess = (userData) => {
        setIsSignedIn(true);
        setUser(userData); // 假设 userData 包含 { username } 等信息
        console.log('Signed in as:', userData.username);
    };

    const handleSignOut = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
            method: 'POST',
            credentials: 'include',
        }).then(() => {
            console.log('Successfully logged out!');
            setUser(null);
            setIsSignedIn(false);
        });
    };

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (
        <div className="App">
            <h1>{t('welcomeMessage')}</h1>

            <LanguageSwitcher />

            {!isSignedIn ? (
                <div className="auth-container">
                    {isSignUpVisible ? (
                        <div>
                            <Signup />
                            <p onClick={() => setIsSignUpVisible(false)}>{t('signininfo')}</p>
                        </div>
                    ) : (
                        <div>
                            <Signin onSignInSuccess={handleSignInSuccess} />
                            <SigninWithGoogle onSignInSuccess={handleSignInSuccess} />
                            <p onClick={() => setIsSignUpVisible(true)}>{t('signupinfo')}</p>
                        </div>
                    )}
                </div>
            ) : (
                <div className="layout-container">
                    <div className="sidebar">
                        <h1>{t('welcomeMessage')}</h1>
                        <LanguageSwitcher />
                        <p>{t('greeting')}{user?.username}!</p>
                        <button onClick={() => handleSectionChange('symbols')}>{t('symbols')}</button>
                        <button onClick={() => handleSectionChange('createSymbol')}>{t('createSymbol')}</button>
                        <button onClick={() => handleSectionChange('userPnl')}>{t('myPnl')}</button>
                        <button onClick={() => handleSectionChange('chat')}>{t('chat')}</button>
                        <button onClick={() => handleSectionChange('leaderboard')}>{t('leaderboard')}</button>
                        <button onClick={handleSignOut}>{t('signOut')}</button>
                    </div>

                    <div className="main-content">
                        {/* Sections */}
                        {activeSection === 'symbols' && (
                            <div className="section">
                                <SymbolList owner={user?.username} />
                            </div>
                        )}
                        {activeSection === 'createSymbol' && (
                            <div className="section">
                                <CreateSymbol owner={user?.username} />
                            </div>
                        )}
                        {activeSection === 'userPnl' && (
                            <div className="section">
                                <UserPnlDetails username={user?.username} />
                            </div>
                        )}
                        {activeSection === 'chat' && (
                            <div className="section">
                                <AnonymousChat username={user?.username}/>
                            </div>
                        )}
                        {activeSection === 'leaderboard' && (
                            <div className="section">
                                <Leaderboard />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
