import React, { useState } from 'react';
import './cssfiles/Form.css'; // 创建一个单独的 CSS 文件来存放表单样式

const Signin = ({ onSignInSuccess }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [message, setMessage] = useState('');

    // 定义多语言的翻译
    const translationsforsignin = {
        en: {
            title: 'Sign In',
            email: 'Email',
            password: 'Password',
            signin: 'Sign In',
            success: 'Sign-in successful!',
            fail: 'Sign-in failed: ',
            error: 'An error occurred. Please try again.'
        },
        zh: {
            title: '登录',
            email: '邮箱',
            password: '密码',
            signin: '登录',
            success: '登录成功！',
            fail: '登录失败：',
            error: '发生错误，请重试。'
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsforsignin[curlanguage][key]; // 翻译函数

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
                credentials: 'include'
            });

            if (response.ok) {
                const result = await response.json();
                setMessage(t('success'));
                onSignInSuccess({
                    username: result.username || 'Default Username',
                    email: formData.email,
                });
            } else {
                const errorData = await response.json();
                setMessage(t('fail') + errorData.message);
            }
        } catch (error) {
            setMessage(t('error'));
        }
    };

    return (
        <div className="wrapper">
            <h2>{t('title')}</h2>
            {message && <p className="message">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="input-field">
                    <label></label>
                    <input
                        type="email"
                        placeholder={t('email')}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input-field">
                    <label></label>
                    <input
                        type="password"
                        name="password"
                        placeholder={t('password')}
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">{t('signin')}</button>
            </form>
        </div>
    );
};

export default Signin;
