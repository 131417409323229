import React, { useState, useEffect } from 'react';

const PlaceOrder = ({ symbol, currentPrice, owner, socket, onPriceUpdate, onClose, sendMessage }) => {
    const [orderType, setOrderType] = useState('buy'); // 默认买单
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(currentPrice); // 初始价格为 currentPrice
    const [message, setMessage] = useState('');

    // 定义多语言翻译
    const translationsForPlaceOrder = {
        en: {
            title: 'Place Order for',
            orderType: 'Order Type:',
            buy: 'Buy',
            sell: 'Sell',
            quantity: 'Quantity:',
            price: 'Price:',
            placeOrder: 'Place Order',
            close: 'Close',
            success: 'Order placed successfully! Updated Price:',
            fail: 'Failed to place order:',
            error: 'An error occurred. Please try again.',
            priceRange: 'Price must be between',
        },
        zh: {
            title: '下订单：',
            orderType: '订单类型：',
            buy: '买入',
            sell: '卖出',
            quantity: '数量：',
            price: '价格：',
            placeOrder: '下单',
            close: '关闭',
            success: '订单成功！最新价格：',
            fail: '下单失败：',
            error: '发生错误，请重试。',
            priceRange: '价格必须在',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsForPlaceOrder[curlanguage][key]; // 翻译函数

    // 更新价格范围
    useEffect(() => {
        const adjustedPrice = orderType === 'buy' 
            ? Math.min(currentPrice + 0.1, 1) 
            : Math.max(currentPrice - 0.1, 0);
        setPrice(adjustedPrice);
    }, [orderType, currentPrice]);

    const handleOrder = async () => {
        // 检查价格范围
        const minPrice = Math.max(currentPrice - 0.1, 0);
        const maxPrice = Math.min(currentPrice + 0.1, 1);
        
        if (price < minPrice || price > maxPrice) {
            setMessage(`${t('priceRange')} ${minPrice.toFixed(2)} ${t('and')} ${maxPrice.toFixed(2)}`);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/${symbol}/place-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderType,
                    quantity,
                    price,  // 传递价格
                    username: owner, // 使用 username 而不是 sender
                }),
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();

                const updatedPrice = data.updatedSymbol.currentPrice;
                setMessage(`${t('success')} ${data.updatedSymbol.currentPrice}`);

                // if (onPriceUpdate) onPriceUpdate(updatedPrice);
                // if (socket) {
                //     socket.send(
                //         JSON.stringify({
                //             type: 'priceUpdate',
                //             symbol,
                //             updatedPrice,
                //         })
                //     );
                // }
                sendMessage({
                    type: 'priceUpdate',
                    symbol,
                    newPrice: updatedPrice,
                });

                if (onClose) onClose();
            } else {
                const errorData = await response.json();
                setMessage(`${t('fail')} ${errorData.message}`);
            }
        } catch (error) {
            setMessage(t('error'));
        }
    };

    return (
        <div className="place-order">
            <h3>{t('title')} {symbol}</h3>
            <label>
                {t('orderType')}
                <select 
                    value={orderType} 
                    onChange={(e) => setOrderType(e.target.value)}
                >
                    <option value="buy">{t('buy')}</option>
                    <option value="sell">{t('sell')}</option>
                </select>
            </label>
            <label>
                {t('quantity')}
                <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    min="1"
                />
            </label>
            <label>
                {t('price')}
                <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                    step="0.01" // 确保每次增减 0.01
                    min={Math.max(currentPrice - 0.01, 0)} // 设置最小值为 currentPrice - 0.01，且不低于 0
                    max={Math.min(currentPrice + 0.01, 1)} // 设置最大值为 currentPrice + 0.01，且不超过 1
                />
            </label>
            <button onClick={handleOrder}>{t('placeOrder')}</button>
            {message && <p>{message}</p>}
            <button onClick={onClose}>{t('close')}</button>
        </div>
    );
};

export default PlaceOrder;
