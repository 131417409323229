import React, { useState, useEffect } from 'react';

const LanguageSwitcher = () => {
    const [currentLanguage, setCurrentLanguage] = useState('en');

    // 加载当前语言设置
    useEffect(() => {
        const savedLanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文
        setCurrentLanguage(savedLanguage);
    }, []);

    const switchToEnglish = () => {
        localStorage.setItem('app-language', 'en'); // 保存语言到 localStorage
        window.location.reload(); // 刷新页面
    };

    const switchToChinese = () => {
        localStorage.setItem('app-language', 'zh'); // 保存语言到 localStorage
        window.location.reload(); // 刷新页面
    };

    return (
        <div>
            <button
                onClick={switchToEnglish}
                disabled={currentLanguage === 'en'}
            >
                English
            </button>
            <button
                onClick={switchToChinese}
                disabled={currentLanguage === 'zh'}
            >
                中文
            </button>
        </div>
    );
};

export default LanguageSwitcher;
