import React, { useState } from 'react';
import useWebSocket from './useWebSocket';
import './AnonymousChat.css';

const AnonymousChat = ({ username }) => {
    // const { sendMessage, chatMessages } = useWebSocket("ws://localhost:5001");
    const { sendMessage, chatMessages, priceUpdates } = useWebSocket("wss://api.projectsaaaaampleeeee.com:443");
    const [input, setInput] = useState("");

    const translationsForChat = {
        en: {
            title: 'Anonymous Chat',
            description: 'No one will know your name. When you leave this page, your chat will be cleared.',
            noMessages: 'No messages yet. Start the conversation!',
            placeholder: 'Type a message...',
            send: 'Send',
        },
        zh: {
            title: '匿名聊天',
            description: '没人会知道你的名字。当你离开此页面时，聊天记录将被清除。',
            noMessages: '还没有消息，开始聊天吧！',
            placeholder: '输入消息...',
            send: '发送',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; 

    const t = (key) => translationsForChat[curlanguage][key]; 

    const handleSend = () => {
        if (input.trim()) {
            sendMessage({
                type: 'chatMessage',
                user: username,
                message: input,
            });
            setInput(""); // 清空输入框
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-header">
                <h1>{t('title')}</h1>
                <p>{t('description')}</p>
            </div>
            <div className="chat-messages">
                {chatMessages.length > 0 ? (
                    chatMessages.map((msg, index) => (
                        <div
                            key={index}
                            className={`chat-message ${
                                msg.user === username ? 'user-message' : 'other-message'
                            }`}
                        >
                            <p className="message-text">{msg.message}</p>
                        </div>
                    ))
                ) : (
                    <p className="no-messages">{t('noMessages')}</p>
                )}
            </div>
            <div className="chat-input-container">
                <input
                    className="chat-input"
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={t('placeholder')}
                />
                <button className="chat-send-button" onClick={handleSend}>
                    {t('send')}
                </button>
            </div>
        </div>
    );
};

export default AnonymousChat;