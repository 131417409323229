import React, { useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';

const SigninWithGoogle = ({ onSignInSuccess }) => {
    const handleCredentialResponse = (response) => {
        // Extract the Google ID token
        const idToken = response.credential;
        // Send ID token to backend for verification
        fetch(` ${process.env.REACT_APP_API_URL}/api/auth/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                idToken: idToken,  // Send token to backend
            }),
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);  // Handle backend response (e.g., user data)
            onSignInSuccess(data.user);  // Pass the user data to parent
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div>
            <GoogleLogin
                onSuccess={handleCredentialResponse}
                onError={() => console.log('Login Failed')}
            />
        </div>
    );
};

export default SigninWithGoogle;
