import React, { useState, useEffect } from 'react';

const UserPnlDetails = ({ username }) => {
    const [pnlDetails, setPnlDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    // 定义多语言翻译
    const translationsForPnlDetails = {
        en: {
            title: 'PnL Details for',
            loading: 'Loading PnL details...',
            noData: 'No PnL details available',
            symbol: 'Symbol',
            orderType: 'Order Type',
            price: 'Price',
            quantity: 'Quantity',
            currentPrice: 'Current Price',
            pnl: 'PnL',
            date: 'Date',
        },
        zh: {
            title: '盈亏详情：',
            loading: '正在加载盈亏详情...',
            noData: '暂无盈亏详情',
            symbol: '符号',
            orderType: '订单类型',
            price: '价格',
            quantity: '数量',
            currentPrice: '当前价格',
            pnl: '盈亏',
            date: '日期',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsForPnlDetails[curlanguage][key]; // 翻译函数

    useEffect(() => {
        fetchPnlDetails();
    }, []);

    const fetchPnlDetails = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${username}/pnl-details`, {
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setPnlDetails(data.pnlDetails);
            } else {
                console.error('Failed to fetch PnL details');
            }
        } catch (error) {
            console.error('Error fetching PnL details:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h3>{t('title')} {username}</h3>
            {loading ? (
                <p>{t('loading')}</p>
            ) : pnlDetails.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>{t('symbol')}</th>
                            <th>{t('orderType')}</th>
                            <th>{t('price')}</th>
                            <th>{t('quantity')}</th>
                            <th>{t('currentPrice')}</th>
                            <th>{t('pnl')}</th>
                            <th>{t('date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pnlDetails.map((trade, index) => (
                            <tr key={index}>
                                <td>{trade.symbol}</td>
                                <td>{trade.orderType}</td>
                                <td>${trade.price.toFixed(2)}</td>
                                <td>{trade.quantity}</td>
                                <td>${trade.currentPrice.toFixed(2)}</td>
                                <td
                                    style={{
                                        color: trade.pnl >= 0 ? 'green' : 'red',
                                    }}
                                >
                                    ${trade.pnl.toFixed(2)}
                                </td>
                                <td>{new Date(trade.date).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>{t('noData')}</p>
            )}
        </div>
    );
};

export default UserPnlDetails;
