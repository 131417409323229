import React, { useState, useEffect } from 'react';

const OrderBook = ({ symbol, currentUser }) => {
    const [orderBook, setOrderBook] = useState({ buyOrders: [], sellOrders: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    // 定义多语言翻译
    const translationsForOrderBook = {
        en: {
            title: 'Order Book for',
            buyOrders: 'Buy Orders',
            sellOrders: 'Sell Orders',
            price: 'Price',
            quantity: 'Quantity',
            cancel: 'Cancel',
            loading: 'Loading order book...',
            fetchError: 'Failed to fetch order book.',
            cancelSuccess: 'Order canceled successfully',
            cancelError: 'Failed to cancel order:',
        },
        zh: {
            title: '订单簿：',
            buyOrders: '买单',
            sellOrders: '卖单',
            price: '价格',
            quantity: '数量',
            cancel: '取消',
            loading: '正在加载订单簿...',
            fetchError: '加载订单簿失败。',
            cancelSuccess: '订单取消成功',
            cancelError: '取消订单失败：',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsForOrderBook[curlanguage][key]; // 翻译函数

    // 获取订单簿
    useEffect(() => {
        const fetchOrderBook = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/${symbol}/orderbook`, {
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setOrderBook(data);
                } else {
                    const errorData = await response.json();
                    setError(errorData.message);
                }
            } catch (error) {
                console.error('Error fetching order book:', error);
                setError(t('fetchError'));
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrderBook();
    }, [symbol]);

    // 取消订单
    const cancelOrder = async (orderId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/${symbol}/orderbook/cancel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ orderId, username: currentUser }),
            });

            if (response.ok) {
                alert(t('cancelSuccess'));
                // 刷新订单簿
                setOrderBook((prev) => ({
                    ...prev,
                    buyOrders: prev.buyOrders.filter(order => order._id !== orderId),
                    sellOrders: prev.sellOrders.filter(order => order._id !== orderId),
                }));
            } else {
                const errorData = await response.json();
                alert(`${t('cancelError')} ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error canceling order:', error);
            alert(t('fetchError'));
        }
    };

    if (isLoading) {
        return <p>{t('loading')}</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <h3>{t('title')} {symbol}</h3>
            <div>
                <h4>{t('buyOrders')}</h4>
                <ul>
                    {orderBook.buyOrders.map(order => (
                        <li key={order._id}>
                            {t('price')}: {order.openPrice}, {t('quantity')}: {order.quantity}
                            {order.username === currentUser && (
                                <button onClick={() => cancelOrder(order._id)}>{t('cancel')}</button>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h4>{t('sellOrders')}</h4>
                <ul>
                    {orderBook.sellOrders.map(order => (
                        <li key={order._id}>
                            {t('price')}: {order.openPrice}, {t('quantity')}: {order.quantity}
                            {order.username === currentUser && (
                                <button onClick={() => cancelOrder(order._id)}>{t('cancel')}</button>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default OrderBook;
