import { useEffect, useState } from 'react';

const useWebSocket = (url) => {
    const [socket, setSocket] = useState(null);

    const [chatMessages, setChatMessages] = useState([]);
    const [priceUpdates, setPriceUpdates] = useState([]);

    useEffect(() => {
        const ws = new WebSocket(url);

        ws.onopen = () => {
            console.log("Connected to WebSocket server");
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log("Message from server:", data);

            if (data.type === 'chatMessage') {
                // Handle chat messages
                setChatMessages((prev) => [...prev, { message: data.message }]);
               
            } 
            else if (data.type === 'priceUpdate') {
                // Handle price updates
                setPriceUpdates((prev) => [...prev, data]);
            } 
            else {
                console.warn("Unknown message type:", data.type);
            }
        };

        //     console.log("Message from server:", event.data);
        //     setMessages((prevMessages) => [...prevMessages, event.data]);
        // };

        ws.onclose = () => {
            console.log("WebSocket connection closed.");
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        setSocket(ws);

        return () => {
            ws.close();
        };
    }, [url]);

    const sendMessage = (message) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        }
    };

    return { sendMessage, chatMessages, priceUpdates};
};

export default useWebSocket;
