import React, { useEffect, useState } from 'react';

const Leaderboard = () => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null); // 当前选中的用户
    const [pnlDetails, setPnlDetails] = useState([]); // 当前用户的 PnL 明细
    const [loadingDetails, setLoadingDetails] = useState(false); // 明细加载状态
    const [language, setLanguage] = useState('en'); // 当前语言

    const translations = {
        en: {
            leaderboard: 'Leaderboard',
            username: 'Username',
            totalPnl: 'Total PnL',
            viewDetails: 'View Details',
            loading: 'Loading leaderboard...',
            userDetails: "{username}'s PnL Details",
            backToLeaderboard: 'Back to Leaderboard',
            symbol: 'Symbol',
            orderType: 'Order Type',
            price: 'Price',
            quantity: 'Quantity',
            currentPrice: 'Current Price',
            pnl: 'PnL',
            date: 'Date',
            noData: 'No details found.',
            loadingDetails: 'Loading details...',
        },
        zh: {
            leaderboard: '排行榜',
            username: '用户名',
            totalPnl: '总盈亏',
            viewDetails: '查看明细',
            loading: '正在加载排行榜...',
            userDetails: "{username} 的 PnL 明细",
            backToLeaderboard: '返回排行榜',
            symbol: '符号',
            orderType: '订单类型',
            price: '价格',
            quantity: '数量',
            currentPrice: '当前价格',
            pnl: '盈亏',
            date: '日期',
            noData: '没有找到明细数据。',
            loadingDetails: '正在加载明细...',
        },
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('app-language') || 'en';
        setLanguage(savedLanguage);
        fetchLeaderboard(); // 初次加载时调用
    }, []);

    const t = (key, params = {}) => {
        let text = translations[language][key] || key;
        Object.keys(params).forEach(param => {
            text = text.replace(`{${param}}`, params[param]);
        });
        return text;
    };

    const fetchLeaderboard = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/leaderboard`);
            if (response.ok) {
                const data = await response.json();
                setLeaderboard(data);
            } else {
                throw new Error('Failed to fetch leaderboard');
            }
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
            setError(error.message);
        }
    };

    const fetchPnlDetails = async (username) => {
        setLoadingDetails(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${username}/pnl-details`, {
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setPnlDetails(data.pnlDetails);
                setSelectedUser(username); // 设置当前选中的用户
            } else {
                throw new Error('Failed to fetch PnL details');
            }
        } catch (error) {
            console.error('Error fetching PnL details:', error);
            setError(error.message);
        } finally {
            setLoadingDetails(false);
        }
    };

    const renderLeaderboard = () => (
        leaderboard.map(user => (
            <div key={user.username} style={{ marginBottom: '10px' }}>
                <strong>{t('username')}:</strong> {user.username} <br />
                <strong>{t('totalPnl')}:</strong> {(user.totalPnl ? parseFloat(user.totalPnl) : 0).toFixed(2)} {/* 安全调用 toFixed */}
                <button onClick={() => fetchPnlDetails(user.username)} style={{ marginLeft: '10px' }}>
                    {t('viewDetails')}
                </button>
            </div>
        ))
    );

    const renderPnlDetails = () => (
        <div>
            <h3>{t('userDetails', { username: selectedUser })}</h3>
            <button onClick={() => setSelectedUser(null)}>{t('backToLeaderboard')}</button>
            {pnlDetails.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>{t('symbol')}</th>
                            <th>{t('orderType')}</th>
                            <th>{t('price')}</th>
                            <th>{t('quantity')}</th>
                            <th>{t('currentPrice')}</th>
                            <th>{t('pnl')}</th>
                            <th>{t('date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pnlDetails.map((detail, index) => (
                            <tr key={index}>
                                <td>{detail.symbol}</td>
                                <td>{detail.orderType}</td>
                                <td>{detail.price.toFixed(2)}</td>
                                <td>{detail.quantity}</td>
                                <td>{detail.currentPrice.toFixed(2)}</td>
                                <td>{detail.pnl.toFixed(2)}</td>
                                <td>{new Date(detail.date).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>{t('noData')}</p >
            )}
        </div>
    );

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h2>{t('leaderboard')}</h2>
            {selectedUser ? (
                loadingDetails ? (
                    <p>{t('loadingDetails')}</p >
                ) : (
                    renderPnlDetails()
                )
            ) : (
                leaderboard.length > 0 ? renderLeaderboard() : <p>{t('loading')}</p >
            )}
        </div>
    );
};

export default Leaderboard;