import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CandleChart = ({ symbol }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async (symbol) => {
      if (!symbol) {
        console.error("Symbol is undefined or missing!");
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/${symbol}/price-history`, {
          credentials: 'include',
        });
        
        // Check for a successful response
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Ensure the response is in JSON format
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          console.log("Fetched Data:", data); // Log fetched data

          if (data && data.history && data.history.length > 0) {
            // Process the data only if fetched data is valid
            const groupedByDay = {};

            // Group data by day
            data.history.forEach(entry => {
              const date = new Date(entry.timestamp);
              const day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

              if (!groupedByDay[day]) {
                groupedByDay[day] = [];
              }

              groupedByDay[day].push(entry);
            });

            console.log("Grouped Data By Day:", groupedByDay); // Log grouped data

            // Calculate open, high, low, and close for each day
            const formattedData = Object.keys(groupedByDay).map(day => {
              const dayData = groupedByDay[day];

              const openPrice = dayData[0].price; // First price of the day
              const closePrice = dayData[dayData.length - 1].price; // Last price of the day
              const lowPrice = Math.min(...dayData.map(entry => entry.price)); // Minimum price of the day
              const highPrice = Math.max(...dayData.map(entry => entry.price)); // Maximum price of the day

              const normalizedDate = new Date(day);
              normalizedDate.setHours(0, 0, 0, 0);

              return {
                x: normalizedDate, // Use normalized date
                y: [openPrice, highPrice, lowPrice, closePrice] // y-axis: [open, high, low, close]
              };
            });

            console.log("Formatted Data for Chart:", formattedData); // Log formatted data before setting it

            if (formattedData.length > 0) {
              setChartData(formattedData); // Set the processed data into chartData state
            } else {
              console.log("Formatted data is empty!"); // Log if formatted data is empty
            }
          } else {
            console.log("Fetched data is empty or invalid!"); // Log if fetched data is invalid
          }
        } else {
          throw new Error(`Invalid JSON response. Content-Type: ${contentType}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    if (symbol) {
      fetchData(symbol);
    }
  }, [symbol]); // Run when `symbol` changes

  const options = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    title: {
      text: "Price History Candlestick Chart"
    },
    axisX: {
      valueFormatString: "YYYY-MM-DD",
      interval: 1, // Ensure one grid line per day
      intervalType: "day" // Set interval type to day
    },
    axisY: {
      title: "Price",
      prefix: "$"
    },
    data: [
      {
        type: "candlestick",
        showInLegend: true,
        name: "Price History",
        yValueFormatString: "$###0.00",
        dataPoints: chartData // Use the processed chartData
      }
    ]
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default CandleChart;
