// src/helpers/fetchNews.js
import axios from 'axios';

async function fetchNews(query = 'technology', language = 'en') {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/news/fetch-news`, {
            params: {
                query,
                language,
            },
        });

        return response.data; // 返回新闻数据
    } catch (error) {
        console.error('Error fetching news:', error.message);
        return null;
    }
}

export default fetchNews;
