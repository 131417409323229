import React, { useState, useEffect } from 'react';
import PlaceOrder from './PlaceOrder';
import fetchNews from '../helperfunction_to_getnews';
import SetResult from './SetResult';
import CandleChart from './candlemap';
import OrderBook from './orderbook';
import useWebSocket from './useWebSocket';
import './SymbolListcss.css'; 

const SymbolList = ({ owner }) => {
    const [symbols, setSymbols] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [newsArticles, setNewsArticles] = useState([]);
    const [isLoadingNews, setIsLoadingNews] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);  // Track the current page
    const itemsPerPage = 5;
    const [selectedSymbolDetails, setSelectedSymbolDetails] = useState(null); 
    const { sendMessage, chatMessages, priceUpdates } = useWebSocket("wss://api.projectsaaaaampleeeee.com:443");
    // const { sendMessage, chatMessages, priceUpdates } = useWebSocket("ws:http://34.130.128.83:8080");
    // 定义多语言翻译
    const translationsForSymbolList = {
        en: {
            allSymbols: 'All Symbols',
            trade: 'Trade',
            symbol: 'Title',
            initialPrice: 'Initial Price',
            currentPrice: 'Current Price',
            detailsFor: 'Details for',
            description: 'Description',
            owner: 'Owner',
            unknown: 'Unknown',
            news: 'News',
            loadingNews: 'Loading news...',
            noNews: 'No news found for',
            priceHistory: 'Price History',
            tradingDisabled: 'Trading is disabled for this symbol as the result is already set.',
            close: 'Close',
            previous: 'Previous',
            next: 'Next',
        },
        zh: {
            allSymbols: '所有符号',
            trade: '交易',
            symbol: '名称',
            initialPrice: '初始价格',
            currentPrice: '当前价格',
            detailsFor: '详情：',
            description: '介绍',
            owner: '所有者',
            unknown: '未知',
            news: '新闻',
            loadingNews: '正在加载新闻...',
            noNews: '未找到相关新闻：',
            priceHistory: '价格历史',
            tradingDisabled: '由于结果已设置，此符号的交易已被禁用。',
            close: '关闭',
            previous: '往前',
            next: '往后',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsForSymbolList[curlanguage][key]; // 翻译函数

    // 获取所有符号
    useEffect(() => {
        const fetchSymbols = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/all`, {
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setSymbols(data);
                } else {
                    console.error('Failed to fetch symbols');
                }
            } catch (error) {
                console.error('Error fetching symbols:', error);
            }
        };

        fetchSymbols();
    }, []);

    const paginatedSymbols = symbols.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Go to the next page
    const handleNextPage = () => {
        if (currentPage * itemsPerPage < symbols.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Go to the previous page
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        if (priceUpdates) {
            const updateSymbolPrice = (updatedSymbol) => {
                setSymbols((prevSymbols) =>
                    prevSymbols.map((symbol) =>
                        symbol.symbol === updatedSymbol.symbol
                            ? { ...symbol, currentPrice: updatedSymbol.newPrice }
                            : symbol
                    )
                );
    
                if (selectedSymbol?.symbol === updatedSymbol.symbol) {
                    setSelectedSymbol((prev) => ({
                        ...prev,
                        currentPrice: updatedSymbol.newPrice, 
                    }));
                }
            };
    
            priceUpdates.forEach((update) => updateSymbolPrice(update));
        }
    }, [priceUpdates]);

    // 处理点击 "Trade" 按钮的逻辑
    const handleTradeClick = async (symbolName) => {
        const selected = symbols.find((sym) => sym.symbol === symbolName);
        if (!selected) {
            console.error('Symbol not found in the list.');
            return;
        }

        setSelectedSymbol(selected);
        setIsLoadingNews(true);

        // 获取符号详细信息（包括 owner）
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/${symbolName}/details`, {
                credentials: 'include'
            });
            if (response.ok) {
                const details = await response.json();
                setSelectedSymbolDetails(details);
            } else {
                console.error('Failed to fetch symbol details');
            }

            // 获取相关新闻
            const articles = await fetchNews(symbolName);
            setNewsArticles(articles || []);
        } catch (error) {
            console.error('Error fetching symbol details or news:', error);
        } finally {
            setIsLoadingNews(false);
        }
    };

    const handleClose = () => {
        setSelectedSymbol(null);
        setSelectedSymbolDetails(null);
        setNewsArticles([]);
    };
    
    const updateSymbol = (updatedSymbol) => {
        setSymbols((prevSymbols) =>
            prevSymbols.map((sym) =>
                sym.symbol === updatedSymbol.symbol ? updatedSymbol : sym
            )
        );
        if (selectedSymbol && selectedSymbol.symbol === updatedSymbol.symbol) {
            setSelectedSymbol(updatedSymbol);
        }
    };

    return (
        <div>
            {selectedSymbol && selectedSymbolDetails ? (
                // Trade Area
                <div>
                    <h3>
                        {t('detailsFor')} {selectedSymbol.symbol} — {t('owner')}: {selectedSymbolDetails.owner || t('unknown')}
                    </h3>

                    <SetResult
                        symbol={selectedSymbolDetails.symbol}
                        owner={selectedSymbolDetails.owner}
                        currentUser={owner}
                        currentResult={selectedSymbolDetails.result}
                        currentPrice={selectedSymbolDetails.currentPrice}
                        deadline={selectedSymbolDetails.deadline}
                        onUpdate={updateSymbol}
                    />

                                            <OrderBook symbol={selectedSymbol.symbol}/>
                    {selectedSymbolDetails.result == null && new Date() <= new Date(selectedSymbolDetails.deadline) ? (
                        <PlaceOrder
                            symbol={selectedSymbolDetails.symbol}
                            currentPrice={selectedSymbolDetails.currentPrice}
                            owner={owner}
                            // onPriceUpdate={(updatedPrice) =>
                            //     updateSymbol({ ...selectedSymbolDetails, currentPrice: updatedPrice })
                            // }
                            onClose={handleClose}
                            sendMessage={sendMessage} 
                        />
                        
                    ) : (
                        <p>{t('tradingDisabled')}</p>
                    )}
                    <OrderBook symbol={selectedSymbolDetails.symbol} currentUser={owner}/>
                    <h4>{t('news')}</h4>
                    {isLoadingNews ? (
                        <p>{t('loadingNews')}</p>
                    ) : newsArticles.length > 0 ? (
                        <ul>
                            {newsArticles.map((article, index) => (
                                <li key={index}>
                                    <strong>{article.title}</strong>
                                    <br />
                                    <em>{article.source.name}</em> - {new Date(article.publishedAt).toLocaleString()}
                                    <br />
                                    <a href={article.url} target="_blank" rel="noopener noreferrer">
                                        Read more
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>{t('noNews')} {selectedSymbol.symbol}</p>
                    )}

                    <h4>{t('priceHistory')}</h4>
                    <CandleChart symbol={selectedSymbolDetails.symbol} />

                    <button onClick={handleClose}>{t('close')}</button>
                </div>
            ) : (
                // Symbol List
                <div>
                    <h2>{t('allSymbols')}</h2>
                    <ul>
                        {paginatedSymbols.map((symbol) => (
                            <li key={symbol._id} className="symbol-item">
                            <div>
                                <strong>{t('symbol')}:</strong> {symbol.symbol}
                            </div>
                            <div>
                                <strong>{t('initialPrice')}:</strong> {symbol.initialPrice}
                            </div>
                            <div>
                                <strong>{t('currentPrice')}:</strong> {symbol.currentPrice}
                            </div>
                            <div className="symbol-description">
                                <strong>{t('description')}:</strong> {symbol.description || "No description available"}
                            </div>
                            <button onClick={() => handleTradeClick(symbol.symbol)}>{t('trade')}</button>
                        </li>
                        ))}
                    </ul>
                    <div className="pagination-controls">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            {t('previous')}
                        </button>
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage * itemsPerPage >= symbols.length}
                        >
                            {t('next')}
                        </button>
                    </div>
                </div>
                
            )}
        </div>
    );
};

export default SymbolList;
