// import React, { useState, useEffect } from 'react';

// const SetResult = ({ symbol, owner, currentUser, currentResult, currentPrice, deadline, onUpdate }) => {
//     const [isSettingResult, setIsSettingResult] = useState(false);
//     const [isBeforeDeadline, setIsBeforeDeadline] = useState(true);

//     useEffect(() => {
//         const now = new Date();
//         const deadlineDate = new Date(deadline);
//         setIsBeforeDeadline(now < deadlineDate);
//     }, [deadline]);

//     const setResult = async (result) => {
//         setIsSettingResult(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/${symbol}/set-result`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     username: currentUser, // 当前用户必须是 owner
//                     result:result,
//                     symbol : symbol,
//                     owner: owner,
//                 }),
//                 credentials: 'include',
//             });

//             if (response.ok) {
//                 const updatedSymbol = await response.json();
//                 alert(`Result set successfully! Price updated to ${result}`);

//                 // 调用父组件的回调函数来更新符号状态
//                 onUpdate(updatedSymbol.symbol);
//             } else {
//                 const errorData = await response.json();
//                 alert(`Failed to set result: ${errorData.message}`);
//             }
//         } catch (error) {
//             console.error('Error setting result:', error);
//             alert('An error occurred while setting the result.');
//         } finally {
//             setIsSettingResult(false);
//         }
//     };

//     return (
//         <div>
//             <h3>Set Result for {symbol}</h3>
//             <p>
//                 <strong>Result:</strong> {currentResult != null ? currentResult : 'Not Set yet'}
//             </p>
//             <p>
//                 <strong>Current Price:</strong> {currentPrice}
//             </p>
//             <p>
//                 <strong>Deadline:</strong> {new Date(deadline).toLocaleString()}
//             </p>
//             {owner === currentUser && currentResult == null ? (
//                 isBeforeDeadline ? (
//                     <div>
//                         <button disabled={isSettingResult} onClick={() => setResult(0)}>
//                             Set Result to 0
//                         </button>
//                         <button disabled={isSettingResult} onClick={() => setResult(1)}>
//                             Set Result to 1
//                         </button>
//                     </div>
//                 ) : (
//                     <p>The deadline has passed. You can no longer set the result.</p>
//                 )
//             ) : (
//                 <p>{owner !== currentUser ? 'Only the owner can set the result.' : 'Result is already set.'}</p>
//             )}
//         </div>
//     );
// };

// export default SetResult;

import React, { useState, useEffect } from 'react';
// import nodemailer from 'nodemailer';

const SetResult = ({ symbol, owner, currentUser, currentResult, currentPrice, deadline, onUpdate }) => {
    const [isSettingResult, setIsSettingResult] = useState(false);
    const [isBeforeDeadline, setIsBeforeDeadline] = useState(true);

    // async function sendEmail(to, subject, text, html = '') {
    //     try {
    //         const transporter = nodemailer.createTransport({
    //             host: 'smtp.gmail.com',
    //             port: 587,
    //             secure: false, // true for 465, false for 587
    //             auth: {
    //                 user: 'swu517592@gmail.com', // replace with your email
    //                 pass: 'bvhl lrxo ynxh kedb' // replace with your email password or app-specific password
    //             },
    //         });
    //         const mailOptions = {
    //             from: '"shuang wu" <swu517592@gmail.com>',
    //             to,
    //             subject,
    //             text,
    //             html,
    //         };
    //         await transporter.sendMail(mailOptions);
    //         console.log(`Email sent to ${to}`);
    //     } catch (error) {
    //         console.error('Error sending email:', error);
    //     }
    // }

    // Define translations
    const translationsForSetResult = {
        en: {
            title: 'Set Result for',
            result: 'Result',
            notSet: 'Not Set yet',
            currentPrice: 'Current Price',
            deadline: 'Deadline',
            deadlinePassed: 'The deadline has passed. You can no longer set the result.',
            setResult0: 'Set Result to 0',
            setResult1: 'Set Result to 1',
            onlyOwner: 'Only the owner can set the result.',
            resultAlreadySet: 'Result is already set.',
            successMessage: 'Result set successfully! Price updated to',
            failMessage: 'Failed to set result: ',
            errorMessage: 'An error occurred while setting the result.',
        },
        zh: {
            title: '为 {symbol} 设置结果',
            result: '结果',
            notSet: '尚未设置',
            currentPrice: '当前价格',
            deadline: '截止日期',
            deadlinePassed: '截止日期已过，您无法再设置结果。',
            setResult0: '将结果设置为 0',
            setResult1: '将结果设置为 1',
            onlyOwner: '只有所有者可以设置结果。',
            resultAlreadySet: '结果已设置。',
            successMessage: '结果设置成功！价格已更新为',
            failMessage: '设置结果失败：',
            errorMessage: '设置结果时发生错误。',
        },
    };

    let curlanguage = localStorage.getItem('app-language') || 'en'; // 默认语言为英文

    const t = (key) => translationsForSetResult[curlanguage][key]; // Translation function

    useEffect(() => {
        const now = new Date();
        const deadlineDate = new Date(deadline);
        setIsBeforeDeadline(now < deadlineDate);
    }, [deadline]);

    const setResult = async (result) => {
        setIsSettingResult(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/symbol/${symbol}/set-result`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: currentUser, // 当前用户必须是 owner
                    result: result,
                    symbol: symbol,
                    owner: owner,
                }),
                credentials: 'include',
            });

            if (response.ok) {
                const updatedSymbol = await response.json();
                alert(`${t('successMessage')} ${result}`);

                // Call the parent component's callback to update symbol state
                onUpdate(updatedSymbol.symbol);
                // 邮件发送函数

                // sendEmail('swu517592@gmail.com', 'Test Subject', 'Hello! This is a test email.', '<h1>Hello!</h1><p>This is a test email.</p>');

            } else {
                const errorData = await response.json();
                alert(`${t('failMessage')} ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error setting result:', error);
            alert(t('errorMessage'));
        } finally {
            setIsSettingResult(false);
        }
    };

    return (
        <div>
            <h3>{t('title')} {symbol}</h3>
            <p>
                <strong>{t('result')}:</strong> {currentResult != null ? currentResult : t('notSet')}
            </p>
            <p>
                <strong>{t('currentPrice')}:</strong> {currentPrice}
            </p>
            <p>
                <strong>{t('deadline')}:</strong> {new Date(deadline).toLocaleString()}
            </p>
            {owner === currentUser && currentResult == null ? (
                isBeforeDeadline ? (
                    <div>
                        <button disabled={isSettingResult} onClick={() => setResult(0)}>
                            {t('setResult0')}
                        </button>
                        <button disabled={isSettingResult} onClick={() => setResult(1)}>
                            {t('setResult1')}
                        </button>
                    </div>
                ) : (
                    <p>{t('deadlinePassed')}</p>
                )
            ) : (
                <p>{owner !== currentUser ? t('onlyOwner') : t('resultAlreadySet')}</p>
            )}
        </div>
    );
};

export default SetResult;
